<template>
  <div id="test-selector-container" style="width: 100%;height: 100%;position: relative;overflow: hidden">

  <div style="padding: 50px;width: 150px;
  border:1px dotted #aaa;position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%)">
    <MetaLevelSelector
    :child-prop-name="'child'"
    :selector-data="testData"
    :value-prop-name="'name'"
    :classify-prop-name="'group'"
    v-model:value="selectorValue"
    :drop-down-container="'#test-selector-container'"
    >
      <template #child="{child}">
        <MetaSelectorOption
        :label="child.name"
        :value="child.name"
        >
          {{ child?.name }}
        </MetaSelectorOption>
      </template>
    </MetaLevelSelector>
  </div>
  </div>
</template>

<script>
import MetaLevelSelector from "./MetaLevelSelector";
import MetaSelectorOption from "./MetaSelectorOption";
export default {
  name: "TestForSelector",
  components: {MetaSelectorOption, MetaLevelSelector},
  data(){
    return {
      selectorValue:'0.1-1.0-2.0-3.0-4.0-5.0-6.0',
      testData:[
        {
          name:'0.0',
          child:[
            {
              name:'0.0-1.0'
            },{
              name:'0.0-1.1'
            }
          ],
          group:'ryo'
        },{
          name:'0.1',
          child:[
            {
              name:'0.1-1.0',
              child:[{
                name:'0.1-1.0-2.0',
                child:[{
                  name:'0.1-1.0-2.0-3.0',
                  child:[{
                    name:'0.1-1.0-2.0-3.0-4.0',
                    child:[{
                      name:'0.1-1.0-2.0-3.0-4.0-5.0',
                      child:[{
                        name:'0.1-1.0-2.0-3.0-4.0-5.0-6.0',
                      }]
                    }]
                  }]
                }]
              }]
            },{
              name:'0.1-1.1'
            }
          ],
          group:'ryo'
        },
        {
          name:'0.2',
          group:'ryo'
        },
        {
          name:'0.3',
          group:'sdl'
        },
        {
          name:'0.4',
          group:'sdl'
        },
        {
          name:'0.5',
          group:'sdl'
        },
        {
          name:'0.6',
          group:'ssda'
        },
        {
          name:'0.7',
          group:'ryo'
        },
        {
          name:'0.8',
          group:'ryo'
        },
        {
          name:'0.9',
          group:'ssda'
        },
        {
          name:'0.10',
          group:'ssda'
        },
        {
          name:'0.11',
          group:'ssda'
        },
        {
          name:'0.12',
          group:'112'
        },
        {
          name:'0.13'
        },
        {
          name:'0.14'
        },
        {
          name:'0.15',
          group:'112'
        },
        {
          name:'0.16',
          group:'112'
        },
        {
          name:'0.17'
        },
        {
          name:'0.18',
          child:[{
            name:'0.18-1.0'
          }]
        }
      ]
    }
  },
  mounted() {
    window.setValue = (val)=>{
        this.selectorValue = val;
    };
  }
}
</script>

<style scoped>

</style>
